import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';


function Applied_accounting() {
    const { i18n, t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);
    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const [courseData, setCourseData] = useState(null);
    const refId = localStorage.getItem('refid') || '';

    useEffect(() => {
        fetch('https://api.millatumidi.uz:7070/courseinfo')
            .then((response) => response.json())
            .then((data) => {
                if (data.data && data.data.length > 0) {
                    setCourseData(data.data[0]);
                }
            })
            .catch((error) => {
                console.error('Error fetching course information:', error);
            });
    }, [])

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h3>{t('ACCA')}</h3>
                                <div className="upper-box">
                                    <div className="embed-responsive custom-video-ratio">
                                        <iframe
                                            src="https://www.youtube.com/embed/RYzlLfQGuAk?si=KynkpraCw2AZZI1z"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        {/*<img className="team-img" src="assets/img/bg/testi_avatar.png" alt="image" />*/}
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/team-single">Bekmuratov Muhtor</Link>
                                                        <p>Dean, Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Faculty
                                                        </a>
                                                        <p>Accounting and Finance</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <p>
                                                            <a href="mailto:m.bekmuratov@cambridge.uz">Email</a>
                                                        </p>
                                                        <p>m.bekmuratov@cambridge.uz</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#">Room</a>
                                                        <p>Branch A, Second floor</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-center">
                                        {/*<Link className="btn" to="/team-acca">*/}
                                        {/*    {t('View Teacher Details')}*/}
                                        {/*</Link>*/}
                                    </div>
                                    <h3>{t('Course Overview')}</h3>
                                    <p>
                                        {t('ACCA text')}.
                                    </p>
                                    <p>
                                        {t('ACCA text 2')}.
                                    </p>
                                    <h4 className="mt-5">Accounting & Finance (ACCA) TEACHING UNDERGRADUATE
                                        CURRICULUM</h4>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER I</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>APPMAT16</td>
                                            <td><Link to={{
                                                pathname: '/subject/APPMAT16',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Applied Mathematics I
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MANINF6</td>
                                            <td><Link to={{
                                                pathname: '/subject/MANINF6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Managing Information
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>BUSCOMS6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BUSCOMS6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Business communication skills
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>BUSTECH6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BUSTECH6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Business and Technology
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>EAP6</td>
                                            <td><Link to={{
                                                pathname: '/subject/EAP6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                English for academic purpose
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER II</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>APPMAT26</td>
                                            <td><Link to={{
                                                pathname: '/subject/APPMAT26',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Applied mathematics 2
                                            </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>ESP6</td>
                                            <td><Link to={{
                                                pathname: '/subject/ESP6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                English for specific purpose
                                            </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>PHILOS3</td>
                                            <td><Link to={{
                                                pathname: '/subject/PHILOS3',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Philosophy
                                            </Link></td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>RELST3</td>
                                            <td><Link to={{
                                                pathname: '/subject/RELST3',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Religious studies
                                            </Link></td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>MAINFR6</td>
                                            <td><Link to={{
                                                pathname: '/subject/MAINFR6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Maintaining financial records
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MANCF6</td>
                                            <td><Link to={{
                                                pathname: '/subject/MANCF6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Managing costs and finance
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER III</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>MANACC16</td>
                                            <td><Link to={{
                                                pathname: '/subject/MANACC16 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Management Accounting I
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTECON6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTECON6 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Introduction to Economics
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINACC16</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINACC16 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Financial Accounting I
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>BUSTECH6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BUSTECH6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Business and technology
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER IV</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>STAT6</td>
                                            <td><Link to={{
                                                pathname: '/subject/STAT6 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Statistics
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MANACC26</td>
                                            <td><Link to={{
                                                pathname: '/subject/MANACC26 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Management Accounting II
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINACC26</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINACC26 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Financial Accounting II
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER V</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>CORBUSL6</td>
                                            <td><Link to={{
                                                pathname: '/subject/CORBUSL6 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Corporate and Business Law
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>ADVMANA6</td>
                                            <td><Link to={{
                                                pathname: '/subject/ADVMANA6 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Advanced Management Accounting
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINREP6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINREP6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Financial reporting
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINMARI6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINMARI6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Financial markets and institutions
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VI</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>PERMAN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/PERMAN6  ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Performance Management
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINMARI6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINMARI6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Financial markets and institutions
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>ADVFINR6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/ADVFINR6 ',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Advanced Financial Reporting
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VII</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>TAXIND6</td>
                                            <td><Link to={{
                                                pathname: '/subject/TAXIND6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Taxation for individuals
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINMAN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINMAN6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Financial management
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>AUDCON6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/AUDCON6',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Auditing in context
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VIII</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>INTERN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTERN6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Internship
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINWOR6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINWOR6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Graduation qualification work
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>{t('Course Features')}</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-home-lg-alt"/>{" "}
                                                <strong>{t('Campus')}:</strong> <span>Branch C</span>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <span className="icon fal fa-book"/>{" "}*/}
                                            {/*    <strong>{t('Lectures')}:</strong> <span>36 </span>*/}
                                            {/*</li>*/}
                                            <li>
                                                <span className="icon fal fa-clock"/>{" "}
                                                <strong>{t('Duration')}: </strong> <span>4 {t('years')}</span>
                                            </li>
                                            <li>
                                                <div className="tuition-fee">
                                                    <span className="icon fal fa-money-bill"/>{" "}
                                                    <strong>{t('Tuition fee (Per Year)')}:</strong> <br/>
                                                    <div className="tuition-fees">
                                                        <div>
                                                            <p>25,000,000 UZS {t('Full time')}</p>
                                                            <p>17,000,000 UZS {t('Part time')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe"/>{" "}
                                                <strong>{t('language')}: </strong> <span>English</span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link target="_blank" to={`https://admission.millatumidi.uz${refId ? `?refid=${refId}` : ''}`}
                                                          className="btn ss-btn smoth-scroll">
                                                        {t('apply')} <i className="fal fa-long-arrow-right"/>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="disclaimer">
                                            <i>*{t('Some discount or scholarship may apply')}</i>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Applied_accounting