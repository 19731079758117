import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';


function English_teaching() {
    const { i18n, t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);
    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const [courseData, setCourseData] = useState(null);
    const refId = localStorage.getItem('refid') || '';

    useEffect(() => {
        fetch('https://api.millatumidi.uz:7070/courseinfo')
            .then((response) => response.json())
            .then((data) => {
                if (data.data && data.data.length > 0) {
                    setCourseData(data.data[0]);
                }
            })
            .catch((error) => {
                console.error('Error fetching course information:', error);
            });
    }, [])

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h3>{t('ELT')}</h3>
                                <div className="upper-box">
                                    <div className="embed-responsive custom-video-ratio">
                                        <iframe
                                            src="https://www.youtube.com/embed/Sj07xYrgSZY?si=tkVvvGf8GIFmaXfn"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        {/*<img className="team-img" src="assets/img/bg/testi_avatar.png" alt="image" />*/}
                                                    </div>
                                                    <div className="text">
                                                    <Link to="/team-single">Ismailov Murod</Link>
                                                        <p>Dean, Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Faculty
                                                        </a>
                                                        <p>English Language Teaching(ELT)</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <p>
                                                            <a href="mailto:m.ismailov@cambridge.uz">Email</a>
                                                        </p>
                                                        <p>m.ismailov@cambridge.uz</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#">Room</a>
                                                        <p>Branch B, Second floor</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3>{t('Course Overview')}</h3>
                                    <p>
                                        {t('elt text')}
                                    </p>
                                    <p>
                                        {t('elt text 2')}
                                    </p>
                                    <p>
                                        {t('elt text 3')}
                                    </p>
                                    <p>
                                        {t('elt text 4')}
                                    </p>
                                    <p>
                                        {t('elt text 5')}
                                    </p>
                                    <p>
                                        {t('elt text 6')}
                                    </p>
                                    <p>
                                        {t('elt text 7')}
                                    </p>
                                    <h4>English Language Teaching (ELT) UNDERGRADUATE CURRICULUM</h4>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER I</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>REWRITI4</td>
                                            <td><Link to={{
                                                pathname: '/subject/REWRITI4',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Reading and writing 1
                                            </Link></td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td>COMPLIT4</td>
                                            <td><Link to={{
                                                pathname: '/subject/COMPLIT4',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Computer literacy
                                            </Link></td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td>PUBSPS4</td>
                                            <td><Link to={{
                                                pathname: '/subject/PUBSPS4',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Public speaking and presentation skills
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MRHUZ4</td>
                                            <td><Link to={{
                                                pathname: '/subject/MRHUZ4',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Most recent history Uzbekistan
                                            </Link></td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td>GRVOCC6</td>
                                            <td><Link to={{
                                                pathname: '/subject/GRVOCC6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Grammar and vocabulary in context
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>LISSPK6</td>
                                            <td><Link to={{
                                                pathname: '/subject/LISSPK6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Listening and speaking
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER II</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>REWRIT24</td>
                                            <td><Link to={{
                                                pathname: '/subject/REWRIT24',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Reading and writing 2
                                            </Link></td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td>INTEGES6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTEGES6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Integreted english skills
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTLIN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTLIN6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Introduction to Linguistics
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTLANL6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTLANL6 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Introduction to Language learning
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>PHILOS3</td>
                                            <td><Link to={{
                                                pathname: '/subject/PHILOS3 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Philosophy
                                            </Link></td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>RELST3</td>
                                            <td><Link to={{
                                                pathname: '/subject/RELST3 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Religious studies
                                            </Link></td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>SFLAN4</td>
                                            <td><Link to={{
                                                pathname: '/subject/SFLAN4 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Second foreign language
                                            </Link></td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER III</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>DEVINC6</td>
                                            <td><Link to={{
                                                pathname: '/subject/DEVINC6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Developing intercultural competence
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTEPM6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTEPM6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Introduction to ELT
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>TLANSC6</td>
                                            <td><Link to={{
                                                pathname: '/subject/TLANSC6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Teaching language system for communication
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>SFLAN4</td>
                                            <td><Link to={{
                                                pathname: '/subject/SFLAN4 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Second foreign language
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>DISANS6</td>
                                            <td><Link to={{
                                                pathname: '/subject/DISANS6 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Discource analysis
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER IV</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>SFLAN4</td>
                                            <td><Link to={{
                                                pathname: '/subject/SFLAN4',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Second foreign language
                                            </Link></td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td>TINLS6</td>
                                            <td><Link to={{
                                                pathname: '/subject/TINLS6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Teaching and Integrating language skills
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>LPCMAN4</td>
                                            <td><Link to={{
                                                pathname: '/subject/LPCMAN4',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Lesson planning and classroom management
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTERN4</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTERN4 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Internship
                                            </Link></td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>10</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER V</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>MEVD6</td>
                                            <td><Link to={{
                                                pathname: '/subject/MEVD6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Materials evaluation and design
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>LANCD6</td>
                                            <td><Link to={{
                                                pathname: '/subject/LANCD6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Language course design
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>LANTA6</td>
                                            <td><Link to={{
                                                pathname: '/subject/LANTA6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Language testing and assessment
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VI</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>INTERN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTERN6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Internship
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINWOR6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINWOR6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Graduation qualification work
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>{t('Course Features')}</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-home-lg-alt"/>{" "}
                                                <strong>{t('Campus')}:</strong> <span>Branch B</span>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <span className="icon fal fa-book"/>{" "}*/}
                                            {/*    <strong>{t('Lectures')}:</strong> <span>36 </span>*/}
                                            {/*</li>*/}
                                            <li>
                                                <span className="icon fal fa-clock"/>{" "}
                                                <strong>{t('Duration')}: </strong> <span>3 {t('years')}</span>
                                            </li>
                                            <li>
                                                <div className="tuition-fee">
                                                    <span className="icon fal fa-money-bill"/>{"  "}
                                                    <strong>{t('Tuition fee (Per Year)')}:</strong> <br/>
                                                    <div className="tuition-fees">
                                                        <div>
                                                            <p>18,000,000 UZS {t('Full time')}</p>
                                                            <p>18,000,000 UZS {t('Night time')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe"/>{" "}
                                                <strong>{t('language')}: </strong> <span>English</span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link target="_blank" to={`https://admission.millatumidi.uz${refId ? `?refid=${refId}` : ''}`}
                                                          className="btn ss-btn smoth-scroll">
                                                        {t('apply')} <i className="fal fa-long-arrow-right"/>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="disclaimer">
                                            <i>*{t('Some discount or scholarship may apply')}</i>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default English_teaching