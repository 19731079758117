import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export function Main() {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [mobile, setmobile] = useState(false);
  const lastRefId = useRef(null); // Using useRef to track the last refId
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle language change
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    localStorage.setItem('selectedLanguage', lang_code);
    navigate(`/${lang_code}`);  // Navigate to the new language's base route
  };

  // Function to toggle the submenu for mobile view
  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // useEffect to handle changes in the URL search parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refid = searchParams.get('refid');
    if (refid && refid !== lastRefId.current) {
      lastRefId.current = refid; // Update the current refId in the ref
      localStorage.setItem('refid', refid);  // Save the refid to localStorage under the key 'refid'
      logReferralVisit(refid);   // Log the referral visit if refid has changed
    }
  }, [location.search]);


  const logReferralVisit = (ref) => {
    const apiUrl = `https://api.admission.millatumidi.uz:7070/users/ref/visit/counter?refid=${encodeURIComponent(ref)}&&type=${encodeURIComponent('website')}`;
    fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          console.log('Referral logged:', data);
        })
        .catch(error => {
          console.error('Error logging referral:', error);
        });
  };

  return (
      <>
        <header className="header-area header-three">
          <div>
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-2 col-lg-3">
                    <div className="millatlogo-section logo">
                      <Link to="/" onClick={scrollToTop}>
                        <img
                            className="millatumidi-logo"
                            src="assets/img/logo/mu-logo.png"
                            alt="logo"
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6">
                    <div className="main-menu text-right text-xl-right">
                      <nav id="mobile-menu">
                        <ul className="header-links">
                          <li className="has-sub">
                            <Link to="/" onClick={scrollToTop}>{t('home')}</Link>
                          </li>
                          <li className="has-sub">
                            <Link to="/courses">{t('courses')}</Link>
                          </li>
                          <li className="has-sub">
                            <Link to="/Admission">{t('admission')}</Link>
                          </li>
                          <li className="has-sub">
                            <Link to="/scholarship">{t('scholarship')}</Link>
                          </li>
                          <li className="has-sub">
                            <Link>{t('about')} <i className="fa fa-caret-down"></i></Link>
                            <ul>
                              <li>
                                <Link to="/About">{t('about')}</Link>
                              </li> <li>
                              <Link to="/faq">{t('faq')}</Link>
                            </li>
                              <li>
                                <Link to="/team">{t('team')}</Link>
                              </li>
                              <li>
                                <Link to="/event">{t('event')}</Link>
                              </li>
                              <li>
                                <Link to="/contact">{t('contact')}</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="has-sub">
                            <Link target="_blank" to="https://ciu.edupage.org/timetable/">{t('timetable')}</Link>
                          </li>
                          <li className="has-sub">
                            <Link target="_blank" to="https://student.cambridge.uz/dashboard/login">Hemis</Link>
                          </li>
                          <li>

                            {/*<li className="has-sub">*/}
                            {/*  <Link to="#">{t('pages')} <i className="fa fa-caret-down"></i></Link>*/}
                            {/*  <ul>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/scholarship">{t('scholarship')}</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/team">{t('team')}</Link>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*</li>*/}
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
                    <div className="login">
                      <ul className="button-x">
                        <li className="language-switcher">
                          <LanguageIcon style={{ marginRight: '8px' }} />
                          <Select
                              value={localStorage.getItem('selectedLanguage')}
                              onChange={onChangeLang}
                              disableUnderline={true}
                          >
                            <MenuItem value="en"><strong>En</strong></MenuItem>
                            <MenuItem value="uz"><strong>Uz</strong></MenuItem>
                            <MenuItem value="ru"><strong>Ru</strong></MenuItem>
                          </Select>
                        </li>

                        <li>
                          <div className="second-header-btn">
                            <Link
                                target="_blank"
                                to={`https://admission.millatumidi.uz${lastRefId.current ? `?refid=${lastRefId.current}` : ''}`}
                                className="btn"
                            >
                              {" "}
                              {t('apply')}{" "}
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mobile-menu mean-container">
                      <li className="language-switcher-mobile">
                        <LanguageIcon style={{ marginRight: '8px' }} />
                        <Select
                            value={localStorage.getItem('selectedLanguage')}
                            onChange={onChangeLang}
                            disableUnderline={true}
                        >
                          <MenuItem value="en"><strong>En</strong></MenuItem>
                          <MenuItem value="uz"><strong>Uz</strong></MenuItem>
                          <MenuItem value="ru"><strong>Ru</strong></MenuItem>
                        </Select>
                      </li>
                      <div className="mean-bar">
                        <a
                            href="#nav"
                            onClick={() => setmobile(!mobile)}
                            className={`meanmenu-reveal ${mobile && "meanclose"}`}
                            style={{
                              right: 0,
                              left: "auto",
                              textAlign: "center",
                              textIndent: 0,
                              fontSize: 18,
                            }}
                        >
                          {mobile ? (
                              "X"
                          ) : (
                              <span>
                            <span>
                              <span></span>
                            </span>
                          </span>
                          )}
                        </a>
                        {mobile && (

                            <nav className="mean-nav">
                              <ul style={{display: "block"}}>
                                <li className="has-sub">
                                  <Link to="/" onClick={scrollToTop}>{t('home')}</Link>
                                </li>
                                <li className="has-sub">
                                  <Link to="/courses">{t('courses')}</Link>
                                </li>
                                <li className="has-sub">
                                  <Link to="/Admission">{t('admission')}</Link>
                                </li>
                                <li className="has-sub">
                                  <Link to="/scholarship">{t('scholarship')}</Link>
                                </li>
                                <li className="has-sub" onClick={toggleSubMenu}>
                                  <a>{t('about')} <i className="fa fa-caret-down"></i></a>
                                  <ul style={{display: isSubMenuOpen ? 'block' : 'none'}}>
                                    <li>
                                      <Link to="/About">{t('about')}</Link>
                                    </li>
                                    <li>
                                      <Link to="/faq">{t('faq')}</Link>
                                    </li>
                                    <li>
                                      <Link to="/team">{t('team')}</Link>
                                    </li>
                                    <li>
                                      <Link to="/event">{t('event')}</Link>
                                    </li>
                                    <li>
                                      <Link to="/contact">{t('contact')}</Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="has-sub">
                                  <Link target="_blank" to="https://ciu.edupage.org/timetable/">{t('timetable')}</Link>
                                </li>
                                <li className="has-sub">
                                  <Link target="_blank" to="https://student.cambridge.uz/dashboard/login">Hemis</Link>
                                </li>
                                {/* <li className="has-sub">
                                  <Link to="/#">Pages</Link>
                                  {Services && (
                                      <ul style={{ display: "block" }}>
                                        <li>
                                          <Link to="/scholarship">Scholarship</Link>
                                        </li>
                                        <li>
                                          <Link to="/team">Teacher</Link>
                                        </li>
                                      </ul>
                                  )}
                                  <a
                                      className={`mean-expand ${
                                          mobile && "mean-clicked"
                                      }`}
                                      onClick={() => {
                                        setServices(!Services);
                                      }}
                                      href="#"
                                      style={{ fontSize: 18 }}
                                  >
                                    {Services ? "-" : "+"}
                                  </a>
                                </li>*/}
                              </ul>
                            </nav>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
  );
}

export default Main;