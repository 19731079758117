import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Background from '../../assets/img/gallery/university-new.jpg'
import Backgroundtwo from '../../assets/img/gallery/University2.jpg'
import ModalImage from '../../assets/img/bg/modal.png'; // Import the image here
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

function Sliderone() {
    const { t } = useTranslation();
    const refId = localStorage.getItem('refid') || '';
    const [show, setShow] = useState(true); // Modal is visible on load

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const settings = {
        autoplay: true,
		autoplaySpeed: 5000,
		dots:false ,
		fade: true,
		arrows: false,
		responsive: [
			{ breakpoint: 1200, settings: { dots: false, arrows: false } }
		]
      };
    
    return (
    <>
        <section id="home" className="slider-area fix p-relative">
        <Slider className="slider-active" style={{ background: "#141b22" }} {...settings}>
                <div>
                    <div className="single-slider slider-bg filter-img"  style={{ backgroundImage: `url(${Background})` , backgroundSize: "cover" }} >
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="slider-content s-slider-content mt-130">
                            <h5 data-animation="fadeInUp" data-delay=".4s">
                                {t('Welcome To MU University')}
                            </h5>
                            <h2 data-animation="fadeInUp" data-delay=".4s">
                                {t('Become a global citizen with us')}
                            </h2>
                            <p data-animation="fadeInUp" data-delay=".6s">
                                {t('Sliderone sub text')}
                            </p>
                            <div className="slider-btn mt-30">
                                <Link target="_blank" to={`https://admission.millatumidi.uz${refId ? `?refid=${refId}` : ''}`}
                                      className="btn-first btn ss-btn mr-15" data-animation="fadeInLeft" data-delay=".4s" >
                                    {t('apply')}<i className="fal fa-long-arrow-right" />
                                </Link>
                                <Link to="/contact" className="btn ss-btn active" data-animation="fadeInLeft" data-delay=".4s" >
                                    {t('contact')}<i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 p-relative"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div>

                <div className="single-slider slider-bg" style={{ backgroundImage: `url(${Backgroundtwo})`, backgroundSize: "cover" }} >
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="slider-content s-slider-content mt-130">
                            <h5 data-animation="fadeInUp" data-delay=".4s">
                                {t('Welcome To MU University')}

                            </h5>
                            <h2 data-animation="fadeInUp" data-delay=".4s">
                                {t('apply')}
                            </h2>
                            <p data-animation="fadeInUp" data-delay=".6s">
                                {t('Sliderone sub text 2')}
                            </p>
                            <div className="slider-btn mt-30">
                                <Link to="/admission" className="btn ss-btn mr-15" data-animation="fadeInLeft" data-delay=".4s" >
                                    {t('admission')}<i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 p-relative"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </Slider>
        </section>
        <section className="service-details-two p-relative">
            <div className="container">
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="services-box07">
                    <div className="sr-contner">
                        <div className="icon">
                            <img className="icon-images" src="assets/img/icon/sve-icon4.png" alt="icon01" />
                        </div>
                        <div className="text">
                        <h5 className="degree-words">
                            <Link to="/courses">{t('Bachelors Degree')}</Link>
                        </h5>
                        <Link to="/courses">
                            {t('Read More')} <i className="fal fa-long-arrow-right" />
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="services-box07">
                    <div className="sr-contner">
                        <div className="icon">
                            <img className="icon-images" src="assets/img/icon/sve-icon6.png" alt="icon01" />
                        </div>
                        <div className="text">
                        <h5 className="degree-words">
                            <Link to="/scholarship">{t('scholarship')}</Link>
                        </h5>
                        <Link to="/scholarship">
                            {t('Read More')} <i className="fal fa-long-arrow-right" />
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: '#0056b3', fontWeight: 'bold', fontSize: '24px', textAlign: 'center', width: '100%' }}>
                    ADMISSION IS OPEN!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-content" style={{ textAlign: 'center', padding: '20px' }}>
                <img src={ModalImage} alt="Admission Open" style={{ maxWidth: '100%', borderRadius: '8px' }}/>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center', padding: '20px' }}>
                <Link style={{ margin: '0 10px', fontWeight: 'bold', fontSize: '16px', padding: '20px 35px', textAlign: "center" }} target="_blank" to={`https://admission.millatumidi.uz${refId ? `?refid=${refId}` : ''}`}
                      className="btn-first btn ss-btn mr-15" data-animation="fadeInLeft" data-delay=".4s" >
                    {t('apply')}
                </Link>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default Sliderone