import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function First() {
    const { i18n, t } = useTranslation();
    const refId = localStorage.getItem('refid') || '';

    return (
        <>
            <section className="about-area about-p pt-80 pb-70 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                                data-animation="fadeInRight"
                                data-delay=".4s">
                                <img
                                    className="about-us__img"
                                    src="../../../assets/img/b-lecture.jpg"
                                    alt="photo"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 boxi-1">
                            <div
                                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                                data-animation="fadeInRight"
                                data-delay=".4s">
                                <h2 className="admission-title">{t('Become a global citizen with us')}</h2>
                                <p className="txt-clr">
                                    {t('Sub text')}
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-md-8 mb-50 position-relative">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <video className="embed-responsive-item" controls>
                                        <source src="assets/video/registerV2.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className="faculties-card">
                            <h2 className="text-center mt-40">
                                <i style={{color: "#ff7350"}} className="fal fa-graduation-cap"/> {t('our Programs')}
                            </h2>
                            <div className="faculties-card__box d-flex justify-content-between mt-30">
                                <div className="faculties-card_img">
                                    <img className="admission-img" src="../../../assets/img/admission-2.jpg"
                                         alt="b-lecture"/>
                                </div>
                                <div className="two-cards__faculties">
                                    <div className="col-lg-4 col-md-12 col-sm-12 mb-20">
                                    <div className="services-box07 services-box07--admission admission-card1">
                                            <div className="sr-contner admission-card1__contner">
                                                <div className="icon">
                                                    <img className="icon-images" src="assets/img/icon/sve-icon4.png"
                                                         alt="icon01"/>
                                                </div>
                                                <div className="text">
                                                    <h5>
                                                        <Link to="/courses">{t('Bachelors Degree')}</Link>
                                                    </h5>
                                                    <ul>
                                                        <li>
                                                            <a className="admission-link"
                                                               href="/BSc-in-Software-Engineering">- {t('IT')}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="admission-link" href="/Business-Management">-
                                                                {t('BTEC')}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="admission-link"
                                                               href="/English-language-teaching">-
                                                                {t('ELT')}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="admission-link" href="/Applied-accounting">-
                                                                {t('ACCA')}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="admission-link" href="/Business_national">-
                                                                {t('BM')}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 className="text-center mt-40">
                            <i style={{color: "#ff7350"}} className="fal fa-list-alt"></i> {t('requirements')}
                        </h2>
                        <div className="admission-requirements">
                            <div className="admission-requirements__card">
                                <h4 className="admission-requirements__card--title">{t('Documents to apply')}</h4>
                                <ul>
                                    <li>{t('A copy of passport/ ID card')}</li>
                                    <li>IELTS 5.5+</li>
                                    <li>{t('School leaving certificate (with transcript)')}</li>
                                </ul>
                            </div>
                            <div className="admission-requirements__card">
                                <h4 className="admission-requirements__card--title">{t('Transfer to MU University')}</h4>
                                <ul>
                                    <li>{t('A copy of passport/ ID card')}</li>
                                    <li>{t('School leaving certificate (with transcript)')}</li>
                                    <li>{t('A certificate or diploma indicating that you have completed 1 academic year')}</li>
                                    <li>{t('Transcript and IELTS 6.0')}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="admission-button">
                            <Link
                                target="_blank"
                                to={`https://admission.millatumidi.uz${refId ? `?refid=${refId}` : ''}`}
                                className="btn"
                                style={{fontSize: '20px', padding: '15px 25px', width: '250px', height: '60px', textAlign: 'center'}}
                            >
                                {t('apply')}
                            </Link>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default First