import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Main() {
  const getCurrentYear = () => { return new Date().getFullYear()};
  const { i18n, t } = useTranslation();
  return (
      <>
        <footer
            className="footer-bg footer-p pt-40"
            style= {{
              backgAroundColor: "#125875",
              backgroundImage: "url(assets/img/bg/footer-bg.png)",
            }}
        >
          <div className="footer-top pb-0">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-4 col-lg-4 col-sm-6">
                  <div className="footer-widget mb-30">
                    <div className="f-widget-title">
                      <h2>{t('about')}</h2>
                    </div>
                    <div className="f-contact">
                      <p>
                        {t('Sub text')}
                      </p>
                    </div>
                    <div className="footer-social mt-10">
                      <a target="_blank" href="https://www.facebook.com/millatumidiuniversity">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a target="_blank" href="https://www.instagram.com/millatumidi_university/">
                        <i className="fab fa-instagram" />
                      </a>
                      <a target="_blank" href="https://www.youtube.com/@MillatUmidiUniversity">
                        <i className="fab fa-youtube" />
                      </a>
                      <a target="_blank" href="https://t.me/cambridgeic">
                        <i className="fab fa-telegram" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-sm-6">
                  <div className="footer-widget mb-30">
                    <div className="f-widget-title">
                      <h2>{t('Our Links')}</h2>
                    </div>
                    <div className="footer-link">
                      <ul>
                        <li>
                          <Link to="/about">{t('about')}</Link>
                        </li>
                        <li>
                          <Link target="_blank" to="https://form.asana.com/?k=NZFHNLjURpPBTvgV6wLWAQ&d=1201799106784259">{t('Careers')}</Link>
                        </li>
                        <li>
                          <Link to="/scholarship">{t('scholarship')}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div style={{width: "200px"}} className="col-xl-3 col-lg-3 col-sm-6">
                  <div className="footer-widget mb-30">
                    <div className="f-widget-title">
                    </div>
                    <div className="f-widget-title">
                      <h2>{t('Courses')}</h2>
                    </div>
                    <div className="footer-link">
                      <ul>
                        <li>
                          <Link to="/courses">{t('Undergraduate')}</Link>
                        </li>
                        {/*<li>*/}
                        {/*  <Link to="/courses">{t('Postgraduate')}</Link>*/}
                        {/*</li>*/}
                      </ul>
                    </div>
                  </div>
                </div>
                <div style={{marginRight: "100px"}} className="col-xl-3 col-lg-3 col-sm-6">
                  <div className="footer-widget mb-30">
                    <div className="f-widget-title">
                      <h2>{t('contact')}</h2>
                    </div>
                    <div className="f-contact">
                      <ul>
                        <li>
                          <span className="d-flex align-items-center">
                            <i className="icon fal fa-phone"/>
                          <Link to="tel:+998 71 200 03 06">
                            +998 71 200-03-06
                          </Link>
                        </span>
                        </li>
                        <li>
                          <i className="icon fal fa-envelope" />
                          <span>
                          <Link to="mailto:admission@millatumidi.uz">
                            admission@millatumidi.uz
                          </Link>
                          <br />
                          <Link to="mailto:info@millatumidi.uz">
                            info@millatumidi.uz
                          </Link>
                        </span>
                        </li>
                        <li className="mobile-address" style={{width: "400px"}}>
                          <span className="d-flex align-items-center">
                            <i className="icon fal fa-map-marker-check" />
                            <span className="contactus-info--footer">
                              Chilonzor 2-Charkh Kamolon 100043, Tashkent
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-wrap">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <div className="copy-text"></div>
                </div>
                <div className="col-lg-4 text-center"></div>
                <div className="col-lg-4 text-right text-xl-right">
                  <p className="copyright-text">Copyright &reg; Millat Umidi University {getCurrentYear()}. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
  );
}
export default Main;