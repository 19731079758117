import React from 'react'
import { useTranslation } from 'react-i18next';

function Touch() {
    const { i18n, t } = useTranslation();
    return (
    <>
        <section id="services" className="services-area pt-120 pb-90 fix">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    {/* <h5>
                        <i className="fal fa-graduation-cap" /> Contact name
                    </h5> */}
                    <h2>{t('contact')}</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <img src="assets/img/bg/contact-icon01.png" alt="image" />
                    </div>
                    <div className="services-content2">
                        <h5>
                            <p>Phone</p>
                            <a className="contactus-info" href="tel:+998 71 200 03 06">+998 71 200 03 06</a>
                        </h5>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center active">
                    <div className="services-icon">
                        <img src="assets/img/bg/contact-icon02.png" alt="image" />
                    </div>
                    <div className="services-content2">
                        <h5>
                            <p>Email Address</p>
                            <a className="contactus-info" href="mailto:admission@cambridge.uz">
                                admission@millatumidi.uz
                            </a>
                        </h5>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <img src="assets/img/bg/contact-icon03.png" alt="image" />
                    </div>
                        <div className="services-content2">
                            <h5>
                                <p>Address</p>
                                <p className="contactus-info">3A, Bunyodkor Avenue, 2-Charkh Kamolon MCA, Chilonzor,
                                    Tashkent 100043, Uzbekistan</p>
                                <p className="contactus-info--small">*MCA stands for Mahalla Citizen's Assembly.</p>
                            </h5>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Touch