import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Second() {
    const { i18n, t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);
    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const [courseData, setCourseData] = useState(null);
    const refId = localStorage.getItem('refid') || '';

    useEffect(() => {
        fetch('https://api.millatumidi.uz:7070/courseinfo')
            .then((response) => response.json())
            .then((data) => {
                if (data.data && data.data.length > 0) {
                    setCourseData(data.data[0]);
                }
            })
            .catch((error) => {
                console.error('Error fetching course information:', error);
            });
    }, []);
    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h3>{t('Pearson BTEC Business')}</h3>
                                <div className="upper-box">
                                    <div className="embed-responsive custom-video-ratio">
                                        <iframe
                                            src="https://www.youtube.com/embed/tZ-KDxC62Lg?si=gG8Ks6_70FN1eHHO"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        {/*<img className="team-img" src="assets/img/bg/testi_avatar.png" alt="image"/>*/}
                                                    </div>
                                                    <div className="text">
                                                    <Link to="/team-single">Pan Galina</Link>
                                                        <p>Dean, Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Faculty
                                                        </a>
                                                        <p>Business Management</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <p>
                                                            <a href="mailto:galina.pan@cambridge.uz">Email</a>
                                                        </p>
                                                        <p>galina.pan@cambridge.uz</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#">Room</a>
                                                        <p>Branch C, Fourth floor</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-center">
                                        {/*<Link className="btn" to="/team-btec">*/}
                                        {/*    {t('View Teacher Details')}
                                         </Link>*/}
                                    </div>
                                    <h3>{t('Course Overview')}  </h3>
                                    <p>
                                        {t('btec text')}
                                    </p>
                                    <p>
                                        {t('btec text 2')}
                                    </p>
                                    <b>{t('Why choose Pearson BTEC Higher Nationals?')} </b>
                                    <p>
                                        {t('btec text 3')}
                                    </p>
                                    <b>{t('Who are these qualifications for?')} </b>
                                    <p>
                                        {t('btec text 4')}
                                    </p>
                                    <h4>{t('What you will learn')}</h4>
                                    <p>
                                        {t('btec text 5')}
                                        <br/>
                                        <span>
                                            <b>{t('Problem-solving skills These include:')}</b>
                                            <ul>
                                                <li>- {t('critical thinking')} </li>
                                                <li>- {t('using expert and creative solutions to solve non-routine problems')} </li>
                                                <li>- {t('generating and communicating ideas creatively')}</li>
                                                <li>- {t('using systems and digital technology.')} </li>
                                            </ul>
                                            <b>{t('Independent skills:')}</b>
                                            <ul>
                                                <li>- {t('self-management')}</li>
                                                <li>- {t('adaptability and resilience')}</li>
                                                <li>- {t('self-monitoring and self-development')}</li>
                                                <li>- {t('reflection, planning and prioritising.')}</li>
                                            </ul>
                                            <b>{t('Interpersonal skills:')}</b>
                                            <ul>
                                                <li>- {t('leadership skills')}</li>
                                                <li>- {t('communicating effectively')}</li>
                                                <li>- {t('working with others')}</li>
                                                <li>- {t('negotiating and influencing')}</li>
                                                <li>- {t('presentation skills.')}</li>

                                            </ul>
                                            <b>{t('Commercial skills')}</b>
                                            <ul>
                                                <li>- {t('awareness of the business sector')}</li>
                                                <li>- {t('sales')}</li>
                                                <li>- {t('marketing and promotion')}</li>
                                                <li>- {t('managing and monitoring budgets.')}</li>
                                            </ul>
                                        <b>{t('Business skills')}</b>
                                        <ul>
                                            <li>- {t('awareness of types of companies and legal structures')}</li>
                                            <li>- {t('invoicing')}</li>
                                            <li>- {t('presenting financial information')}</li>
                                            <li>- {t('business management.')}</li>
                                        </ul>
                                        </span>
                                    </p>
                                    <h4>BA IN BUSINESS MANAGEMENT UNDERGRADUATE CURRICULUM</h4>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER I</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>EXPBUS6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM1001',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Exploring Business
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>RESPMC6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM1002',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Research and Plan a Marketing Campaign
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>BUSFIN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM1003',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Business Finance
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTLBUS6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM1005',
                                                state: {courseInfo: courseData}
                                            }}>
                                                International Business
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>APPMAT16</td>
                                            <td>
                                                <Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Applied mathematics
                                            </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER II</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ETC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>DIGMAR6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM1017',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Digital Marketing
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MANEVN6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM1004',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Managing an Event
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>BUSDM6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM1007',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Business Decision Making
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>PRNMAN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM1006',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Principles of Management
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>STECHP6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM1028',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Sales Techniques and Processes
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER III</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ETC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>BUSBENV6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM2001',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Business and the Business Environment
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MARPP6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM2002',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Marketing Processes and Planning
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>HRM6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM2003',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Human Resource Management
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MANSBP6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM2006',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Managing a Successful Business Project
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER IV</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ETC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>LEADMAN6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM2004',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Leadership and Management
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>ACCOUN6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM2005',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Accounting Principles
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Courses
                                            </Link></td>
                                            <td>18</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER V</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ETC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>RESPRO6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM3019',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Research Project
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>ORGBEH6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM3020',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Organisational Behaviour
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MARINA6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM3033',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Marketing Insights and Analytics
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MANRSB6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM3029',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Managing and Running a Small Business
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Courses
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VI</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ETC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>RESPRO6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM3019',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Research Project
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>CONIPL6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM3040',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Consumer and Intellectual Property Law
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>HRVCOS6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BM3047',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Human Resources - Value and Contribution to
                                                Organisational Success
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Courses
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VII</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ETC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>FINPTNS6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM4001',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Financial Principles and Techniques for Non-specialists
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>STRMAN6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BM4002',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Strategic Management
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INCBUS6</td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Innovation and Creativity in Business
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Courses
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VIII</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ETC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>INTERN6</td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Internship
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>FINWOR6</td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Graduation qualification work
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Courses
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>{t('Course Features')}</h3>
                                        <ul className="project-info clearfix">
                                        <li>
                                                <span className="icon fal fa-home-lg-alt"/>{" "}
                                                <strong>{t('Campus')}:</strong> <span>Branch C</span>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <span className="icon fal fa-book"/>{" "}*/}
                                            {/*    <strong>{t('Lectures')}:</strong> <span>36 </span>*/}
                                            {/*</li>*/}
                                            <li>
                                                <span className="icon fal fa-clock"/>{" "}
                                                <strong>{t('Duration')}: </strong> <span>4 {t('years')}</span>
                                            </li>
                                            <li>
                                                <div className="tuition-fee">
                                                    <span className="icon fal fa-money-bill"/>{" "}
                                                    <strong>{t('Tuition fee (Per Year)')}:</strong> <br/>
                                                    <div className="tuition-fees">
                                                        <div>
                                                            <p>25,000,000 UZS {t('Full time')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe"/>{" "}
                                                <strong>{t('language')}: </strong> <span>English</span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link target="_blank"
                                                          to={`https://admission.millatumidi.uz${refId ? `?refid=${refId}` : ''}`}
                                                          className="btn ss-btn smoth-scroll">
                                                        {t('apply')} <i className="fal fa-long-arrow-right"/>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="disclaimer">
                                            <i>*{t('Some discount or scholarship may apply')}</i>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Second